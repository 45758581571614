import Lottie from 'react-lottie-player'

import OnboardingJson from '../../assets/animations/Onboarding-loop-lottie.json'
import { Button, Input, Progress, ProgressProps } from 'antd';
import SvgImportIcon from '../../assets/IconComponents/ImportIcon';
import { t } from 'i18next';
import GoogleLogo from '../../assets/logos/GoogleLogo'
import AirbnbLogo from '../../assets/logos/AirbnbLogo'
import McdonaldsLogo from '../../assets/logos/McdonaldsLogo'
import NovartisLogo from '../../assets/logos/NovartisLogo'
import Icon from '../../components/Icon';
import { useEffect, useState } from 'react';
import { useCloneBrandMutation, useCreateFromUrlMutation } from '../../services/BrandApi';
import SvgPartyBaloon from '../../assets/IconComponents/PartyBaloon';
import TextArea from 'antd/es/input/TextArea';
import { useLazyDownloadQuery } from '../../services/FileApi';
import { blobToDataURL } from 'blob-util';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLastSelectedBrandId } from '../../store/persistedSlice';
import SvgLoading from '../../assets/IconComponents/Loading';


export interface IOnboardingImportBrandPopupProps {
    // onContinue: (string) => void;
}


const progressColor: ProgressProps['strokeColor'] = {
    '0%': 'red',
    '100%': 'red',
};


function OnboardingImportBrandPopup(props: IOnboardingImportBrandPopupProps) {

    const [brandColors, setBrandColors] = useState<string[]>([]);
    const [logoImage, setLogoImage] = useState<string>("")
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [panelState, setPanelState] = useState<"lottie" | "loading" | "done">("lottie");
    const [importProgress, setImportProgeress] = useState(0);
    const [importTimeoutId, setImportTimeoutId] = useState<any | undefined>(undefined);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [
        createBrandFromUrl,
        { isLoading: creatingBrand, isError: createBrandFromUrlError, isSuccess: createBrandFromUrlSuccess, data: createdBrand },
    ] = useCreateFromUrlMutation();
    const [downloadFile, { data: fileDownloaddData, isSuccess: fileDownloaded, isLoading: fileDownloading, currentData: dt }] = useLazyDownloadQuery()
    const [cloneBrand, { data: clonedBrand, isSuccess: cloneBrandSuccess }] = useCloneBrandMutation()

    useEffect(() => {
        if (panelState !== "loading") {
            return;
        }
        if (importTimeoutId != undefined) {
            clearTimeout(importTimeoutId);
        }
        var progress = Math.round((10 * Math.random()));
        if (progress == 0) {
            progress = 1;
        }

        var id = setTimeout(() => {
            if (importProgress < 90) {
                setImportProgeress(importProgress + progress)
            }
        }, 1000 * (Math.random() * 3));
        setImportTimeoutId(id);
    }, [importProgress, panelState])

    useEffect(() => {
        if (createBrandFromUrlSuccess || cloneBrandSuccess) {
            setImportProgeress(100);
            setPanelState("done");
            if (createdBrand) {
                dispatch(setLastSelectedBrandId(createdBrand?.id));
            } else if (clonedBrand) {
                dispatch(setLastSelectedBrandId(clonedBrand?.id));
            }
        }
    }, [createBrandFromUrlSuccess, cloneBrandSuccess])

    useEffect(() => {
        var logo = createdBrand?.logo_file_ids
        if (logo && (logo?.length ?? 0) - 1 > -1) {
            downloadFile({
                id: logo[(logo?.length ?? 0) - 1].id ?? ""
            })
                .unwrap()
                .then(async k => {
                    let image = await blobToDataURL(k.file)
                    setLogoImage(image)
                })
        }
    }, [createdBrand])

    useEffect(() => {
        var logo = clonedBrand?.logo_file_ids
        if (logo && (logo?.length ?? 0) - 1 > -1) {
            downloadFile({
                id: logo[(logo?.length ?? 0) - 1].id ?? ""
            })
                .unwrap()
                .then(async k => {
                    let image = await blobToDataURL(k.file)
                    setLogoImage(image)
                })
        }
    }, [clonedBrand])

    useEffect(() => {
        if (createdBrand?.brand_colors) {
            const colors = createdBrand.brand_colors?.split(",");
            setBrandColors(colors);
        }
    }, [createdBrand])

    useEffect(() => {
        if (clonedBrand?.brand_colors) {
            const colors = clonedBrand.brand_colors?.split(",");
            setBrandColors(colors);
        }
    }, [clonedBrand])

    function checkURLValid(str) {
        var urlRegex =
            "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
        var url = new RegExp(urlRegex, "i");
        return str.length < 2083 && url.test(str);
    }

    function getBrandName(url: string | undefined) {
        var parsedUrl = new URL(url ?? "");
        parsedUrl.hostname
        var hostName = parsedUrl.hostname;
        if (hostName != undefined && (hostName?.length ?? 0) > 0) {
            hostName = hostName.replace("www.", "")
            hostName = hostName.substring(0, hostName.indexOf("."));
            return hostName?.charAt(0).toUpperCase() + hostName?.slice(1);
        }
        return hostName;
    }


    return <div className="flex flex-row w-full">

        {(panelState === "lottie" || panelState === "loading") &&
            < div className="flex flex-col w-1/2 py-10 px-7 gap-7 justify-center">

                <div className='flex flex-col w-full gap-[10px]'>
                    <span className='font-medium text-black text-[30px] leading-[40px] text-left'>{"Let’s import your brand"}</span>
                    <span className='font-regualar text-black text-[14px] leading-[22px] text-left'>{"Simply enter your website address and we’ll import your brand information such as your logo, colors and font to help you create awesome designs!"}</span>
                </div>
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex flex-row w-full gap-3'>
                        <Input addonBefore="https://" size='large' onChange={(val) => { setWebsiteUrl(val.target.value); }}></Input>
                        <Button
                            type="primary"
                            disabled={panelState !== "lottie"}
                            onClick={() => {
                                const websiteUrlFull = "https://" + websiteUrl;
                                if (checkURLValid(websiteUrlFull)) {
                                    setPanelState("loading");
                                    createBrandFromUrl({ brandFromUrlRequest: { web_url: websiteUrlFull, brand_name: getBrandName(websiteUrlFull) } })
                                }
                            }}
                            icon={panelState === "lottie" ? <SvgImportIcon fill="#fff" className="mr-2" /> : <SvgLoading fill='gray' className="mr-2" />}
                            size='large'
                        >{t("import")}</Button>
                    </div>
                    <div className='relative flex h-[22px] gap-[6px] items-center'>
                        <div className="flex-grow border-t border-gray-400"></div>
                        <span className='font-medium text-[14px] leading-[22px] text-gray text-center'>{"Or try a template brand"}</span>
                        <div className="flex-grow border-t border-gray-400"></div>
                    </div>

                    <div className='flex flex-row gap-2'>
                        <Button size='large' onClick={() => { setPanelState("loading"); cloneBrand({ cloneBrandRequest: { brand_id: "0a80f11d-fd12-4443-8e8e-cc7b7f1e3c1e" } }); }}><GoogleLogo /></Button>
                        <Button size='large' onClick={() => { setPanelState("loading"); cloneBrand({ cloneBrandRequest: { brand_id: "210006ac-8fc8-4b87-baec-4c30f5802bc4" } }); }}><AirbnbLogo /></Button>
                        <Button size='large' onClick={() => { setPanelState("loading"); cloneBrand({ cloneBrandRequest: { brand_id: "7a3ade15-3eb9-428d-8383-ca498e9fdbc7" } }); }}><McdonaldsLogo /></Button>
                        <Button size='large' onClick={() => { setPanelState("loading"); cloneBrand({ cloneBrandRequest: { brand_id: "1424b966-9b7c-4044-8271-97906eb42a30" } }); }}><NovartisLogo /></Button>
                    </div>
                </div>
            </div>
        }
        {
            panelState === "done" &&
            < div className="flex flex-col w-1/2 py-10 px-7 gap-7 justify-center">
                <div className='flex flex-col py-10 px-7 gap-7 items-center'>
                    <div
                        style={{ backgroundColor: 'rgba(247, 130, 146, 0.1)' }}
                        className="rounded w-[56px] h-[56px] flex items-center justify-center"
                    >
                        <Icon component={SvgPartyBaloon} width="28px" height="28px" viewBox="0 0 28 28" />
                    </div>
                    <div className='flex flex-col gap-[10px]'>
                        <span className='font-medium text-[30px] leading-[40px] text-center'>{`Your brand ${createdBrand !== undefined ? createdBrand?.brand_name : (clonedBrand !== undefined ? clonedBrand.brand_name : "")} is imported!`}</span>
                        <span className='font-normal text-[14px] leading-[22px] text-center'>{`Now let’s create an ad and see it in action.`}</span>
                    </div>
                    <div className='flex flex-row gap-3'>
                        <Button size='large' onClick={() => {
                            if (createdBrand !== undefined) {
                                navigate("/app/brandhub/new", { state: { brand: createdBrand } });
                            }
                            else if (clonedBrand !== undefined) {
                                navigate("/app/brandhub/new", { state: { brand: clonedBrand } });
                            }
                        }}>
                            {`Edit Brand`}
                        </Button>
                        <Button size='large' type='primary' onClick={() => {
                            navigate("/app/home")
                        }}>
                            {`Create an Ad`}
                        </Button>
                    </div>
                </div>

            </div>
        }

        <div className="w-1/2">
            {panelState === "lottie" &&
                <Lottie
                    loop
                    animationData={OnboardingJson}
                    play
                />
            }
            {
                panelState === "loading" &&
                <div className='bg-bg flex flex-row h-[640px] shrink-0 justify-center items-center'>
                    <div className='bg-white rounded h-[300px] w-3/4 flex flex-col justify-center p-4 gap-3'>
                        <span className='text-black font-medium text-[24px] leading-8 text-left'>{`${importProgress}%`}</span>
                        <Progress percent={importProgress} showInfo={false} strokeColor={progressColor} />
                        <span className='text-black font-inter-tight text-[12px] leading-5 text-left'>{"We’re importing your brand info..."}</span>

                    </div>
                </div>
            }
            {
                panelState === "done" &&
                <div className='bg-red flex flex-row h-[640px] shrink-0 justify-center items-center'>
                    <div className='bg-white rounded-3xl h-[300px] w-3/4 flex flex-col justify-center p-4 gap-4'>
                        <div className='flex flex-col w-full gap-2'>
                            <span className='font-normal text-[14px] leading-[22px]'>{`Summary`}</span>
                            <TextArea
                                placeholder=""
                                autoSize={{ minRows: 3, maxRows: 3 }}
                                value={createdBrand !== undefined ? createdBrand?.about_company : (clonedBrand !== undefined ? clonedBrand.about_company : "")}
                            />
                        </div>
                        <div className='flex flex-row gap-4'>
                            <div className='flex flex-col gap-2 w-1/3'>
                                <span className='font-normal text-[14px] leading-[22px]'>{`Logo`}</span>
                                <div className='h-[100px] rounded-sm border-1 relative flex justify-center p-3' style={{
                                    backgroundSize: "cover", // Adjust the sizing as needed
                                    backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
                                }}>
                                    <img src={logoImage} alt={createdBrand !== undefined ? createdBrand?.brand_name : (cloneBrand !== undefined ? clonedBrand?.brand_name : "")} className="object-scale-down" />
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 w-1/3'>
                                <span className='font-normal text-[14px] leading-[22px]'>{`Colors`}</span>
                                <div className='h-[100px] relative rounded-sm border-1 justify-center items-center'>
                                    {brandColors && brandColors.length > 0 && <div style={{ backgroundColor: `${brandColors[0]}` }} className={`absolute top-1/2 transform -translate-y-1/2 translate-x-[18px] z-20 rounded-full w-[28px] h-[28px]`}></div>}
                                    {brandColors && brandColors.length > 1 && <div style={{ backgroundColor: `${brandColors[1]}` }} className={`absolute top-1/2 transform -translate-y-1/2 translate-x-[32px] z-10 rounded-full w-[28px] h-[28px]`}></div>}
                                    {brandColors && brandColors.length > 2 && <div style={{ backgroundColor: `${brandColors[2]}` }} className={`absolute top-1/2 transform -translate-y-1/2 translate-x-[46px] z-0  rounded-full w-[28px] h-[28px]`}></div>}
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 w-1/3'>
                                <span className='font-normal text-[14px] leading-[22px]'>{`Fonts`}</span>
                                <div className='h-[100px] rounded-sm border-1 flex justify-center items-center'>
                                    <span style={{ fontFamily: `${createdBrand !== undefined ? createdBrand?.primary_font_file_id?.name : (clonedBrand !== undefined ? clonedBrand.primary_font_file_id?.name : "")}` }} className='text-black text-center font-medium text-[40px] leading-[48px]'>{`Aa`}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </div>

    </div >
}

export default OnboardingImportBrandPopup;